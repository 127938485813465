import {inject, Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SOFTLINE_MASTER_DETAIL_HEIGHT} from '@softline/ui-core';

@Injectable({ providedIn: 'root' })
export class ShellService {
  readonly #contentRect = new ReplaySubject<DOMRect>(1);

  readonly #masterDetailHeight = inject(SOFTLINE_MASTER_DETAIL_HEIGHT, {
    optional: true,
  });

  readonly contentRect$ = this.#contentRect.asObservable();

  setContentRect(value: DOMRect): void {
    this.#contentRect.next(value);
  }

  constructor() {
    this.contentRect$.pipe(
      takeUntilDestroyed(),
    ).subscribe((rect) => {
      this.#masterDetailHeight?.set(rect.height);
    });
  }
}
