import { AddOn, CommandStore, SOFTLINE_FEATURE_COMMANDS } from '@softline/application';
import {
  BelegStore,
  GesamtabfrageListComponent,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, WithBelegart
} from '@softapps/wws/gesamtabfrage';
import { base64Encode, Store } from '@softline/core';
import { combineLatestWith, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION } from '@softline/auth';
import { SOFTLINE_PERMISSION_BUCHUNGSBRIEF } from '../buchungsbrief.shared';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable()
export class GesamtabfrageListAddOn
  implements AddOn<GesamtabfrageListComponent>
{
  type = GesamtabfrageListComponent;

  constructor(private store: Store, private router: Router) {
  }

  async onAttach(host: GesamtabfrageListComponent): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: GesamtabfrageListAddOn,
        priority: -1,
        commands: [
          {
            icon: 'fa-regular fa-envelope-open-text',
            name: '#BUCHUNGSBRIEF.ADD_ON.TITLE',
            class: 'menu action-menu action-menu-top',
            isVisible: this.store
              .observe(
                SOFTLINE_FEATURE_AUTHORIZATION,
                AuthorizationStore.getters.authorized,
                SOFTLINE_PERMISSION_BUCHUNGSBRIEF
              )
              .pipe(
                combineLatestWith(
                  host.belegart$
                ),
                tap(o => console.log('-----', o)),
                map(
                  ([isAuthorized, belegart]) =>
                    isAuthorized && belegart?.id === 'Rechnungspruefung'
                )
              ),
            canExecute: this.store
              .observe(
                SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
                BelegStore.getters.selected
              )
              .pipe(map((o) =>
                o.length === 1
                && !!(o[0] as any)?.id
                && (o[0] as any).kzbelegart !== 'T'
                && (o[0] as any).kzbelegart !== 'U')),
            execute: () => {
              const queryParams = this.store.get(
                SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
                BelegStore.getters.query.query
              );
              const selected = this.store.get(
                SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
                BelegStore.getters.selected
              );
              if (selected.length !== 1) return;

              const beleg = selected[0];
              const fromRoute = !queryParams
                ? { url: ['/gesamtabfrage', beleg.belegart.id, 'find'] }
                : {
                    url: ['/gesamtabfrage', beleg.belegart.id, 'list'],
                    extras: {
                      queryParams: {
                        query: base64Encode(JSON.stringify(queryParams)),
                      },
                    },
                  };

              this.router.navigate(['/buchungsbrief', (beleg as any).id], {
                queryParams: { from: base64Encode(JSON.stringify(fromRoute)) },
              });
            },
          },
        ],
      }
    );
  }

  async onDetach(host: any): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      GesamtabfrageListAddOn
    );
  }
}
