import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { isDefinedNotEmpty, LocalStorageService } from '@softline/core';
import { lastValueFrom } from 'rxjs';

export const terminalGuard: CanActivateFn = async (route, state) => {
  const service = inject(LocalStorageService);
  const router = inject(Router);
  try {
    const terminalRoute = await lastValueFrom(service.get<string>({key: 'SoftAppsTerminal'}));
    if(isDefinedNotEmpty(terminalRoute))
      return router.parseUrl(terminalRoute);
  }
  catch (e) {
    return true;
  }
  return true;
};
