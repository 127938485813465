<soft-page>
  <soft-page-header>
    @if (visibleCurrent(); as current) {
      <soft-default-page-header-content
        class="w-full"
        [overline]="
        '#SOFTAPPS_CORE.PAGES.STEPPER.OVERLINE'
          | translate
          | interpolate
            : { current: visibleIndex() + 1, total: visibleSteps().length }
      "
        [title]="current?.title() | translate"
        [subtitle]="current?.description() | translate"
      ></soft-default-page-header-content>
    }
  </soft-page-header>
  @if (currentTemplate(); as template){
    <ng-container *ngTemplateOutlet="template"></ng-container>
  }
</soft-page>

