import {
  Component, computed,
  contentChild,
  forwardRef,
  input,
  output,
  OutputEmitterRef,
  signal,
  TemplateRef,
  viewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkPortal } from '@angular/cdk/portal';
import { StepState, WizardItemBase } from '../wizard-item-base';
import { contentTemplate } from '@angular-devkit/schematics';

@Component({
  selector: 'soft-wizard-step',
  standalone: true,
  imports: [CommonModule, CdkPortal],
  templateUrl: './wizard-step.component.html',
  styleUrl: './wizard-step.component.scss',
  providers: [
    {
      provide: WizardItemBase,
      useExisting: forwardRef(() => WizardStepComponent),
    },
  ],
})
export class WizardStepComponent extends WizardItemBase {
  override title = input.required<string>();
  override description = input<string>();
  override icon = input<string>();

  override state = input<StepState>('default');
  override canEnter = input(true);
  override canLeave = input(true);

  override enter = output<this>();
  override leave = output<this>();
  override next = output<{ current: WizardItemBase; next: WizardItemBase }>();

  viewTemplate = viewChild(TemplateRef);
  contentTemplate = contentChild(TemplateRef);
  template = computed(() => this.contentTemplate() || this.viewTemplate());


  constructor() {
    super();
  }
}
