import {
  AddOn,
  CommandStore,
  SOFTLINE_FEATURE_COMMANDS,
} from '@softline/application';
import {
  FreigabeListComponent,
  FreigabeStore,
  SOFTLINE_FEATURE_FREIGABE,
  Workflow,
  WorkflowData,
} from '@softapps/fibu/freigabe';
import { base64Encode, Store } from '@softline/core';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SOFTLINE_PERMISSION_BUCHUNGSBRIEF } from '../buchungsbrief.shared';
import {
  AuthorizationStore,
  SOFTLINE_FEATURE_AUTHORIZATION,
} from '@softline/auth';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class FreigabeListAddOn implements AddOn<FreigabeListComponent> {
  type = FreigabeListComponent;

  constructor(private store: Store, private router: Router) {}

  async onAttach(host: FreigabeListComponent): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: FreigabeListAddOn,
        priority: -1,
        commands: [
          {
            icon: 'fa-regular fa-envelope-open-text',
            name: '#BUCHUNGSBRIEF.ADD_ON.TITLE',
            class: 'menu action-menu action-menu-top',
            isVisible: this.store.observe(
              SOFTLINE_FEATURE_AUTHORIZATION,
              AuthorizationStore.getters.authorized,
              SOFTLINE_PERMISSION_BUCHUNGSBRIEF
            ),
            canExecute: host.selected$.pipe(
              map((o) => (o?.detail as any)?.korrekturMoeglich)
            ),
            execute: async () => {
              const selected = (await firstValueFrom(
                host.selected$
              )) as Workflow & { detail: WorkflowData };
              if (!(selected.detail as any).korrekturMoeglich)
                return;

              const fromRoute = { url: ['/freigabe'] };

              await this.router.navigate(
                ['/buchungsbrief', (selected.detail as any).idkopferw],
                {
                  queryParams: {
                    from: base64Encode(JSON.stringify(fromRoute)),
                  },
                }
              );
            },
          },
        ],
      }
    );
  }

  async onDetach(host: any): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      FreigabeListAddOn
    );
  }
}
