import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  viewChild,
} from '@angular/core';
import { Store } from '@softline/core';
import {
  BreakpointStore,
  MessageBarStore,
  ModalService,
  ObserveElementSizeDirective,
  PullToRefreshContainerComponent,
  SOFTLINE_FEATURE_BREAKPOINTS,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  UiCoreModule,
} from '@softline/ui-core';
import { combineLatestWith, delay, of, Subscription } from 'rxjs';
import * as ShellStore from '../shell.store';
import { SideActionsComponent } from './side-actions/side-actions.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import {
  Command2,
  CommandStore,
  ConsoleSettingsComponent,
  DeveloperStore,
  MenuItem,
  MenuItemStore2,
  RefreshService,
  RequestLogListComponent,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_DEVELOPER,
  SOFTLINE_FEATURE_SHELL,
  StoreComponent,
  WithCommands2,
  WithMenuItems,
} from '@softline/application';
import { ShellService } from '../services/shell.service';

@Component({
  standalone: true,
  selector: 'soft-shell',
  animations: [
    trigger('slideInOut_Main', [
      transition(':enter', [
        animate(
          '555ms ease-out',
          keyframes([
            style({ transform: 'translateX(-100%)', offset: 0 }),
            style({ transform: 'translateX(0%)', offset: 0.6 }),
            style({ transform: 'translateX(0%)', offset: 0.97 }),
          ])
        ),
      ]),
      transition(':leave', [
        animate(
          '444ms ease-in',
          keyframes([
            style({ transform: 'translateX(0%)', offset: 0 }),
            style({ transform: 'translateX(-100%)', offset: 0.97 }),
          ])
        ),
      ]),
    ]),
    trigger('slideInOut_Actions', [
      transition(':enter', [
        animate(
          '555ms ease-out',
          keyframes([
            style({ transform: 'translateX(100%)', offset: 0 }),
            style({ transform: 'translateX(0%)', offset: 0.6 }),
            style({ transform: 'translateX(0%)', offset: 0.97 }),
          ])
        ),
      ]),
      transition(':leave', [
        animate(
          '444ms ease-in',
          keyframes([
            style({ transform: 'translateX(0%)', offset: 0 }),
            style({ transform: 'translateX(100%)', offset: 0.97 }),
          ])
        ),
      ]),
    ]),
  ],
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    UiCoreModule,
    RouterModule,
    SideActionsComponent,
    SideMenuComponent,
    TitleBarComponent,
    BottomBarComponent,
    ObserveElementSizeDirective,
  ],
})
export class ShellComponent
  extends WithMenuItems(WithCommands2())
  implements OnInit, OnDestroy
{
  private sizeSubscription?: Subscription;
  readonly #shellService = inject(ShellService);
  private menuItemsStore = inject(MenuItemStore2);
  private modalService = inject(ModalService);

  isTabletScreen = false;
  isDesktopScreen = false;

  isDeveloper = toSignal(
    this.store.isRegistered(SOFTLINE_FEATURE_DEVELOPER)
      ? this.store.observe(
          SOFTLINE_FEATURE_DEVELOPER,
          DeveloperStore.getters.isDeveloper
        )
      : of(false)
  );

  pullToRefreshComponent = viewChild(PullToRefreshContainerComponent, {
    read: ElementRef<HTMLElement>,
  });

  readonly height = computed(() => {
    const height = this.pullToRefreshComponent()?.nativeElement?.scrollHeight;
    console.log('pull to Refresh comp: ', height);
    return this.pullToRefreshComponent()?.nativeElement?.scrollHeight ?? 0;
  });

  menuItems = computed<MenuItem[]>(() => {
    const menuItems: MenuItem[] = [];
    if (this.isDeveloper()) {
      menuItems.push(
        {
          type: 'command',
          name: 'SoftappsCoreModuleStore',
          outlet: 'action-bottom',
          title: 'Store',
          icon: 'fa-regular fa-laptop-code',
        },
        {
          type: 'command',
          name: 'SoftappsCoreModuleRequests',
          outlet: 'action-bottom',
          title: 'Requests',
          icon: 'fa-regular fa-arrow-up-arrow-down',
        },
        {
          type: 'command',
          name: 'SoftappsCoreModuleConsole',
          outlet: 'action-bottom',
          title: 'Console',
          icon: 'fa-regular fa-rectangle-terminal',
        }
      );
    }
    return menuItems;
  });

  commands = computed<Command2[]>(() => [
    {
      name: 'SoftappsCoreModuleConsole',
      execute: async () => {
        this.modalService.open({
          component: ConsoleSettingsComponent,
          dismiss: true,
        });
      },
    },
    {
      name: 'SoftappsCoreModuleStore',
      execute: async () => {
        this.modalService.open({ component: StoreComponent, dismiss: true });
      },
    },
    {
      name: 'SoftappsCoreModuleRequests',
      execute: async () => {
        this.modalService.open({
          component: RequestLogListComponent,
          dismiss: true,
        });
      },
    },
  ]);

  hasActionMenuItems$ = toObservable(this.menuItemsStore.values).pipe(
    map((o) =>
      o
        .flat()
        .filter(
          (item) =>
            (item.outlet === 'action' || item.outlet === 'action-bottom') &&
            (item.visible ?? true)
        )
    ),
    map((o) => o.length > 0)
  );

  hasSideActions$ = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.hasCommands,
      'action-menu'
    )
    .pipe(
      combineLatestWith(this.hasActionMenuItems$),
      map(([a, b]) => a || b)
    );

  isSideMenuOpen$ = this.store.observe(
    SOFTLINE_FEATURE_SHELL,
    ShellStore.getters.sideMenuOpen
  );
  isSideMenuMinified$ = this.store.observe(
    SOFTLINE_FEATURE_SHELL,
    ShellStore.getters.sideMenuMinified
  );
  isActionMenuOpen$ = this.store.observe(
    SOFTLINE_FEATURE_SHELL,
    ShellStore.getters.actionMenuOpen
  );
  isActionMenuMinified$ = this.store.observe(
    SOFTLINE_FEATURE_SHELL,
    ShellStore.getters.actionMenuMinified
  );
  messages$ = this.store.observe(
    SOFTLINE_FEATURE_MESSAGE_BAR,
    MessageBarStore.getters.container
  );

  constructor(private store: Store, public refreshService: RefreshService) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    // Aufgrund der Subscription im store, welche augenblicklich wieder den State ändert
    // muss hier ein kleines Delay gegeben werden.
    // Sonst passiert folgendes:
    // 1. BreakpointStore gibt einen neuen StoreState auf den Stream
    // 2. Dadurch wird die Subscription hier unter aufgerufen
    // 3. Diese schreibt wieder einen neuen State auf den Stream
    // 4. Der Stream vom BreakpointStore wird mit dem alten Wert abgeschlossen (vmtl. noch durch den alten Kontext)
    // 5. Jetzt ist fälschlicherweise der Wert von Punkt 1 am Stream
    this.sizeSubscription = this.store
      .observe(SOFTLINE_FEATURE_BREAKPOINTS, BreakpointStore.getters.breakpoint)
      .pipe(delay(1))
      .subscribe((o) => {
        this.isTabletScreen = o === 'sm' || o === 'md';
        this.isDesktopScreen = o === 'lg' || o === 'xl' || o === '2xl';
        this.onWidthChanged();
      });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.sizeSubscription && !this.sizeSubscription.closed)
      this.sizeSubscription.unsubscribe();
    this.sizeSubscription = undefined;
  }

  onWidthChanged(): void {
    if (this.isDesktopScreen) {
      this.openSideMenu();
      this.openActionMenu();
    } else if (this.isTabletScreen) {
      this.minimizeSideMenu();
      this.minimizeActionMenu();
      this.openSideMenu();
      this.openActionMenu();
    } else {
      this.closeSideMenu();
      this.closeActionMenu();
    }
  }

  openSideMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.openSideMenu
    );
  }
  closeSideMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.closeSideMenu
    );
  }
  minimizeSideMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.minimizeSideMenu
    );
  }
  maximizeSideMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.maximizeSideMenu
    );
  }
  openActionMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.openActionMenu
    );
  }
  closeActionMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.closeActionMenu
    );
  }
  minimizeActionMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.minimizeActionMenu
    );
  }
  maximizeActionMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.maximizeActionMenu
    );
  }

  contentSizeChanged(rect: DOMRect): void {
    this.#shellService.setContentRect(rect);
  }
}
