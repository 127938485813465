import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@softline/core';
import { filter, map } from 'rxjs/operators';
import { SOFTLINE_FEATURE_MODULE } from '../../application.shared';
import * as ModuleStore from '../module.store';

@Injectable()
export class ModuleService {
  constructor(store: Store, router: Router) {
    if (!router?.events) return;

    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        const tree = router.parseUrl(event.urlAfterRedirects);
        let module = '';
        if(tree.root?.hasChildren() && (tree.root.children['primary']?.segments?.length ?? 0) > 0) {
          module = tree.root.children['primary'].segments[0].path
        }
        if(module === 'terminal') {
          if(tree.root?.hasChildren() && (tree.root.children['primary']?.segments?.length ?? 0) > 1) {
            module = tree.root.children['primary'].segments[1].path
          }
        }
        store.commit(
          SOFTLINE_FEATURE_MODULE,
          ModuleStore.mutations.setActive,
          module
        );
      });
  }
}
