import {Directive, ElementRef, NgZone, OnDestroy} from '@angular/core';
import { debounceTime, distinctUntilChanged, ReplaySubject } from 'rxjs';
import { outputFromObservable } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[softObserveElementSize]',
  standalone: true,
})
export class ObserveElementSizeDirective implements OnDestroy {
  isObserving = false;

  private observer?: MutationObserver;
  private resizeObserver?: ResizeObserver;

  readonly #domRect$ = new ReplaySubject<DOMRect>(1);

  readonly contentRect$ = this.#domRect$.pipe(
    debounceTime(100),
    distinctUntilChanged((curr, prev) => {
      return curr.height === prev.height
        && curr.width === prev.width
    })
  );

  readonly sizeChange = outputFromObservable<DOMRect>(this.contentRect$);

  constructor(private elementRef: ElementRef) {
    const element = this.elementRef.nativeElement;

    if (!element) return;

    this.observer = new MutationObserver(() => {
      const elementRect = element.getBoundingClientRect();
      this.#domRect$.next(elementRect);
    });

    this.resizeObserver = new ResizeObserver(() => {
      const elementRect = element.getBoundingClientRect();
      this.#domRect$.next(elementRect);
    });

    if (this.isObserving) {
      this.observer.disconnect();
      this.resizeObserver.disconnect();
    }

    this.isObserving = true;
    this.resizeObserver.observe(element);
    this.observer.observe(element, {
      subtree: true,
      childList: true,
      characterData: false,
      attributeOldValue: false,
      characterDataOldValue: false,
      attributes: true,
      attributeFilter: ['style'],
    });
  }

  ngOnDestroy() {
    this.observer?.disconnect();
    this.resizeObserver?.disconnect();
  }
}
