@if(menuItem(); as menuItem) {
  @if (menuItem?.visible === undefined || menuItem.visible) {
    @if(menuItem.children) {
      <soft-split-button
        [id]="'soft-menu-item-' + menuItem.outlet + '-' + menuItem.name"
        class="flex flex-row flex-nowrap justify-center menu-item-split-button"
        [disabled]="(canExecute() | async) === false || running()"
        [type]="menuItem.class ?? ''"
        (action)="execute(menuItem)">
        <div class="flex flex-row flex-nowrap">
          @if (menuItem.icon) {
            <soft-icon [name]="running() ? 'fa-regular fa-spinner fa-spin' : menuItem.icon"></soft-icon>
          }
          @if(menuItem.title) {
            <span class="menu-item-text"
                  [ngClass]="{'menu-item-has-icon': menuItem.icon}">{{menuItem.title | translate }}</span>
          }
        </div>
        @for(child of menuItem.children; track child.name) {
          <soft-split-option [label]="child.title ?? ''"
                             [icon]="child.icon"
                             (action)="execute(child)"></soft-split-option>
        }
      </soft-split-button>
    }
    @else {
      <button
        [id]="'soft-menu-item-' + menuItem.outlet + '-' + menuItem.name"
        class="flex flex-row flex-nowrap relative menu-item-button"
        [disabled]="(canExecute() | async) === false|| running()"
        [ngClass]="menuItem.class ?? ''"
        (click)="execute(menuItem)"
      >
        @if (menuItem.badge) {
          <span class="soft-badge">{{ menuItem.badge }}</span>
        }
        @if (menuItem.icon) {
          <soft-icon [name]="running() ? 'fa-regular fa-spinner fa-spin' : menuItem.icon"
                      [class.mr-2]="!!menuItem.title"></soft-icon>
        }
        @if(menuItem.title) {
          <span class="menu-item-text"
                [ngClass]="{'menu-item-has-icon': menuItem.icon}">{{menuItem.title | translate }}</span>
        }
      </button>
    }
  }


}
