import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { BackNavigable } from "../back-navigable";
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class BackNavigationService {
  private backNavigable$ = new BehaviorSubject<BackNavigable | undefined>(
    undefined
  );

  canNavigateBack$ = this.backNavigable$.pipe(
    mergeMap(
      (o) => (o ?? { canNavigateBack$: of(false) }).canNavigateBack$ ?? of(true)
    )
  );

  canNavigateBack = toSignal(this.canNavigateBack$, {initialValue: false});

  set(backNavigable?: BackNavigable): void {
    this.backNavigable$.next(backNavigable);
  }

  async navigateBack(): Promise<void> {
    if(!this.canNavigateBack())
      return;
    await this.backNavigable$.value?.navigateBack();
  }
}
