import {
  Component,
  computed,
  contentChild,
  effect,
  ElementRef,
  Inject,
  input,
  NgZone,
  OnDestroy,
  output,
  signal,
  TemplateRef,
  viewChild
} from '@angular/core';
import {CommonModule, DOCUMENT} from '@angular/common';
import {PageComponent} from '../page/page.component';
import {PageHeaderComponent} from '../page/page-header/page-header.component';
import {QueryPageShortcutsComponent} from '../query-page/query-page-shortcuts/query-page-shortcuts.component';
import {MasterDetailComponent, UiCoreModule, WithBreakpoints} from '@softline/ui-core';
import {RemoteDataContainerComponent} from '../../components/remote-data-container/remote-data-container.component';
import {LoadingState} from '@softline/core';
import {CdkPortalOutlet} from '@angular/cdk/portal';
import { MenuOutletComponent } from '../../menu/menu-outlet/menu-outlet.component';

@Component({
  selector: 'soft-master-detail-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    PageHeaderComponent,
    QueryPageShortcutsComponent,
    UiCoreModule,
    RemoteDataContainerComponent,
    CdkPortalOutlet,
    MenuOutletComponent,
  ],
  templateUrl: './master-detail.page.html',
  styleUrl: './master-detail.page.scss',
})
export class MasterDetailPage<T>
  extends WithBreakpoints()
  implements OnDestroy
{
  private masterDetail = viewChild<MasterDetailComponent<T>>('masterDetail');
  private masterDetailElem = viewChild('masterDetail', { read: ElementRef });

  #observer: ResizeObserver | undefined;

  data = input<T[]>([]);
  masterTemplate = input<TemplateRef<any> | null>(null);
  detailTemplate = input<TemplateRef<any> | null>(null);
  detailPlaceholder = input<TemplateRef<any> | null>(null);
  loadingState = input<LoadingState>(null);
  loadingError = input<Error | null>(null);

  readonly #masterDetailOffsetTop = signal(0);

  readonly masterDetailMaxHeightPx = computed(() => {
    return (
      this.document.body.clientHeight - (this.#masterDetailOffsetTop() + 16)
    );
  });

  selectedChange = output<T | null>();

  header = contentChild(PageHeaderComponent, { descendants: true });

  selected = computed(() => {
    const masterDetail = this.masterDetail();
    if (masterDetail) return masterDetail.selected();
    return null;
  });

  select(index: number): void {
    const masterDetail = this.masterDetail();
    if (masterDetail) masterDetail.select(index);
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private zone: NgZone
  ) {
    super();

    this.#observer = new ResizeObserver((entries) => {
      const offestTop = entries[0].target?.getBoundingClientRect().top;
      this.zone.run(() => {
        this.#masterDetailOffsetTop.set(offestTop);
      });
    });

    effect(() => {
      const masterDetailElement =
        this.masterDetailElem()?.nativeElement?.firstElementChild;

      if (!masterDetailElement) {
        return;
      }

      this.#observer?.observe(masterDetailElement);
    });
  }

  ngOnDestroy() {
    this.#observer?.disconnect();
    this.#observer = undefined;
  }
}
