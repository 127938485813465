<div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
  <div class="text-sm xl:col-start-2 xl:row-start-1">
    <div class="flex flex-col soft-card mt-4">
      <div class="soft-overline border-b border-solid">
        {{ '#BUCHUNGSBRIEF.PAGE.BELEGDATEN' | translate }}
      </div>
      <div
        class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
      >
        <span class="whitespace-nowrap">
          {{ '#BUCHUNGSBRIEF.PAGE.BELEGART' | translate }}</span
        >
        <span *ngIf="!detailLoading; else loadingTemplate">{{
          (beleg$ | async)?.beleg
        }}</span>
      </div>
      <div
        class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
      >
        <span class="whitespace-nowrap">
          {{ '#BUCHUNGSBRIEF.PAGE.RECHNUNGSNUMMER' | translate }}</span
        >
        <span *ngIf="!detailLoading; else loadingTemplate">{{
          (beleg$ | async)?.belegnummer
        }}</span>
      </div>
      <div
        class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
      >
        <span class="whitespace-nowrap">
          {{ '#BUCHUNGSBRIEF.PAGE.FREMDBELEGSNUMMER' | translate }}</span
        >
        <span *ngIf="!detailLoading; else loadingTemplate">{{
          (beleg$ | async)?.bezugsnummer
          }}</span>
      </div>
      <div
        class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
      >
        <span class="whitespace-nowrap">
          {{ '#BUCHUNGSBRIEF.PAGE.BELEGDATUM' | translate }}</span
        >
        <span *ngIf="!detailLoading; else loadingTemplate">{{
          (beleg$ | async)?.belegdatum | format: 'DATE.DEFAULT'
        }}</span>
      </div>
      <div
        class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
      >
        <span class="whitespace-nowrap">
          {{ '#BUCHUNGSBRIEF.PAGE.KUNDE' | translate }}</span
        >
        <span *ngIf="!detailLoading; else loadingTemplate"
          >{{ (beleg$ | async)?.ktostammRe?.nummer }} -
          {{ (beleg$ | async)?.ktostammRe?.name1 }}</span
        >
      </div>
      <div
        class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
      >
        <span class="whitespace-nowrap">
          {{ '#BUCHUNGSBRIEF.PAGE.NETTO' | translate }}</span
        >
        <span *ngIf="!detailLoading; else loadingTemplate">{{
          (beleg$ | async)?.bemessung | currencyFormat
        }}</span>
      </div>
      <div
        class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
      >
        <span class="whitespace-nowrap">
          {{ '#BUCHUNGSBRIEF.PAGE.BRUTTO' | translate }}</span
        >
        <span *ngIf="!detailLoading; else loadingTemplate">{{
          (beleg$ | async)?.brutto | currencyFormat
        }}</span>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="flex flex-col soft-card mt-2">
        <div class="soft-overline border-b border-solid">
          {{ '#BUCHUNGSBRIEF.PAGE.FORM' | translate }}
        </div>
        <div
          class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
        >
          <label for="buchungsbriefBelegart" class="soft-label left whitespace-nowrap">{{
            '#BUCHUNGSBRIEF.PAGE.BELEGART' | translate
          }}</label>

          <soft-select
            id="buchungsbriefBelegart"
            formControlName="belegart"
            class="w-1/2 text-left"
            (valueChange)="onBelegartChange()"
          >
            <soft-select-option [value]="'E'">{{
              '#BUCHUNGSBRIEF.PAGE.BUCHUNGSBRIEF' | translate
            }}</soft-select-option>
            <soft-select-option [value]="'H'">{{
              '#BUCHUNGSBRIEF.PAGE.NACHZAHLUNG' | translate
            }}</soft-select-option>
          </soft-select>
        </div>
        <div
          class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
        >
          <label for="buchungsbriefBegruendung" class="soft-label left whitespace-nowrap required">
            {{ '#BUCHUNGSBRIEF.PAGE.BEGRUENDUNG' | translate }}</label
          >
          <div class="flex flex-col w-3/4">
            <soft-multiline-input
              id="buchungsbriefBegruendung"
              formControlName="begruendung"
              [canResize]="true"
              class="text-left"
            ></soft-multiline-input>
            <soft-validation-container
              *ngIf="form.controls['begruendung'].touched"
              [validation]="form|validationMessage:'begruendung'"
              class="soft-property-validation"></soft-validation-container>
          </div>
        </div>

        <div
          class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
        >
          <span class="soft-label left whitespace-nowrap required">
            {{ '#BUCHUNGSBRIEF.PAGE.NETTOWERT' | translate }}</span
          >
          <div class="w-1/2 text-left">
            <table
              *ngIf="listBelegwerte.controls.length > 0"
              class="border border-solid border-secondary w-full"
            >
              <thead>
                <tr class="bg-gray-200">
                  <th
                    class="border border-solid border-secondary text-right !font-bold p-1 pr-3"
                  >
                    {{ '#BUCHUNGSBRIEF.PAGE.FORM_UST' | translate }}
                  </th>
                  <th
                    class="border border-solid border-secondary !font-bold p-1 pl-3"
                  >
                    {{ '#BUCHUNGSBRIEF.PAGE.FORM_NETTO' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container formArrayName="listBelegwerte">
                  <tr
                    *ngFor="
                      let belegwert of listBelegwerte.controls;
                      let i = index
                    "
                    [formGroup]="$any(belegwert)"
                  >
                    <td
                      class="border border-solid border-secondary font-bold text-right bg-gray-200 pr-3"
                    >
                      {{ belegwert.value.ustsatz.ustcode }}
                    </td>
                    <td class="border border-solid border-secondary border-box">
                      <soft-number-input
                        class="border-0 align-right"
                        formControlName="bemessung"
                        format="CURRENCY.DEFAULT"
                        [placeholder]="'#BUCHUNGSBRIEF.PAGE.BETRAG'"
                      ></soft-number-input>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="flex w-full flex-row justify-end">
              <soft-validation-container
                *ngIf="form.controls['listBelegwerte'].touched"
                [validation]="form|validationMessage:'listBelegwerte'"
                class="soft-property-validation"></soft-validation-container>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 w-full">
        <soft-file-input
          formControlName="files"
          selectionMode="multi"
          accept="application/pdf"
        ></soft-file-input>
        <soft-validation-container
          *ngIf="form.controls['files'].touched"
          [validation]="form|validationMessage:'files'"
          class="soft-property-validation"></soft-validation-container>
      </div>
      <div class="w-full flex flex-col justify-center px-4 py-3 -mt-4 border border-primary rounded-sm border-solid bg-primary-50 text-primary-900">
          <div class="flex gap-1 text-primary-500 items-center justify-start font-semibold text-xs tracking-wide">
            INFORMATION
          </div>
          Der hochgeladene Anhang sowie der bearbeitete Ursprungsbeleg werden der Korrektur angehängt.
      </div>
      <button
        [disabled]="form.invalid || (saving$ | async)"
        type="submit"
        class="soft-button primary w-full mt-4"
      >
        <i *ngIf="saving$ | async" class="fa-regular fa-spinner fa-spin"></i>
        {{ '#BUCHUNGSBRIEF.PAGE.SUBMIT' | translate }}
      </button>
    </form>
  </div>
  <div class="relative xl:col-start-1 xl:row-start-1 w-full viewer-height">
    <div class="flex flex-col absolute right-12 top-8 z-10">
      <button
        *ngIf="!documentLoading && document"
        (click)="onEditPdf()"
        class="soft-button secondary  opacity-20 hover:opacity-80"
      >
        Bearbeiten
      </button>
      <button
      *ngIf="!documentLoading && document && isDocumentEdited"
      (click)="onResetPdf()"
      class="soft-button link danger opacity-20 hover:opacity-80"
    >
      Zurücksetzen
    </button>
    </div>
    <soft-pdf-viewer
      [document]="document"
      [loading]="documentLoading"
    ></soft-pdf-viewer>
  </div>
</div>
<ng-template #loadingTemplate>
  <span class="skeleton w-1/2"></span>
</ng-template>
