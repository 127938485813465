import { ObjectStore2, Store2Builder } from '@softline/core';
import { computed, Injectable, InjectionToken } from '@angular/core';
import { SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT } from './authentication.shared';

export interface AuthenticationContext {
  data: {
    userId: number;
    userFullName: string;
    username: string
  } | null;
}

@Injectable()
class AuthContextStore2 extends ObjectStore2<AuthenticationContext> {
  override name = SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT;
  userId = computed(() => {
    return this.value()?.data?.userId;
  });
  userName = computed(() => {
    return this.value()?.data?.username;
  });
  userFullName = computed(() => {
    return this.value()?.data?.userFullName;
  });
  override getDefaultState(): AuthenticationContext | null {
    return {data: null};
  }
}

const builder = new Store2Builder(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT)
  .withFeature(AuthContextStore2);

export const AuthenticationContextStore2 = new InjectionToken<AuthContextStore2>('AuthenticationContextStore2', {
  factory: () => builder.build()
});
