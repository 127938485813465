<div class="grid grid-rows-[auto_1fr_auto] min-h-screen max-h-screen">
  <div class="flex flex-row flex-nowrap w-full bg-primary">

    <div class="flex flex-row flex-nowrap w-full">
      @if(backNavigationService.canNavigateBack()) {
        <div class="ml-3 mr-3 p-4 h-full text-primary-contrast cursor-pointer hover:bg-primary-700" (click)="backNavigationService.navigateBack()">
          <soft-icon name="fa-regular fa-arrow-left"></soft-icon>
        </div>
      } @else {
        <div class="ml-3 mr-3 w-12 py-4 h-full text-primary-contrast">
        </div>
      }
      <div class="flex flex-row py-4 ml-3">
        @if (authenticationContextStore.userId()) {
          <span class="text-primary-contrast">
        {{authenticationContextStore.userFullName() ?? authenticationContextStore.userName()}}
      </span>
        } @else {
          <span class="text-warning">
          {{ '#TERMINAL.NOT_AUTHENTICATED' | translate}}
        </span>
        }
      </div>
    </div>
    <div class="flex flex-row justify-center w-full py-4 ">
    <span class="text-primary-contrast">
      {{titleStore.title()}}
    </span>
    </div>

    <div class="flex flex-row gap-4 w-full justify-end p-4">
    <span class="text-primary-contrast">
       {{time() | format:'DATE_TIME.DEFAULT'}}
    </span>
      @if(networkStatusService.online()) {
        <soft-icon name="fa-regular fa-wifi" class="text-primary-contrast"></soft-icon>
      } @else {
        <soft-icon name="fa-regular fa-wifi-slash" class="text-warning"></soft-icon>
      }
      <soft-icon name="fa-regular fa-ellipsis-v cursor-pointer"
                 class="text-primary-contrast"
                 (click)="showMenu()">
      </soft-icon>
    </div>
  </div>


  <div class="px-4 pt-2 overflow-y-auto overflow-x-hidden">
    <router-outlet></router-outlet>
  </div>

  <soft-bottom-bar></soft-bottom-bar>

</div>

<soft-modal-container></soft-modal-container>
<div class="shell-message-bar-wrapper">
  <soft-message-bar-container class="shell-message-bar-container"
  ></soft-message-bar-container>
</div>
