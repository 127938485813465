@if (hasBottomMenuItems()) {
  <div class="w-full gap-4 px-4 pb-4 pt-2">
    <soft-menu-outlet class="bottom-menu" name="bottom" #bottom></soft-menu-outlet>
  </div>
}


@if (((bottomCommands$ | async)?.length ?? 0) > 0) {
  <div class="w-full gap-4 px-4 pb-4 pt-2">
    <ng-container *ngFor="let command of bottomCommands$ | async">
      <ng-container *ngIf="!command.isVisible || (command.isVisible | async)">
        <button  [id]="'framework-application-shell-menu-commands-bottom-' + command.name"
                 class="soft-button rounded h-16 w-full"
                 *ngVar="
                command.canExecute
                  ? (command.canExecute | async)
                  : true as canExecute
              "
                 [ngClass]="command.class ?? ''"
                 [disabled]="!canExecute"
                 (click)="
                onClick(
                  command.execute,
                  command.routerLink,
                  canExecute,
                  command.routerLinkParams
                )
              ">
          <span class="soft-badge" *ngIf="command?.badge | async as badge">{{ badge }}</span>
          <div class="flex flex-row w-full text-center flex-nowrap justify-center items-center">
            <soft-icon class="mr-2" *ngIf="command.icon" [name]="command.icon"></soft-icon>
            {{command.name | translate}}
          </div>
        </button>
      </ng-container>
    </ng-container>
  </div>
}
@for (content of bottomOutletService.references$ | async; track content) {
  @if (content|instanceof:TemplateRef; as template) {
    <ng-container *ngTemplateOutlet="template"></ng-container>
  }
  @else {
    <ng-container *ngComponentOutlet="$any(content)"></ng-container>
  }
}
@if(bottomTabGroupService.tabGroups$ | async; as tabgroups) {
@if(bottomTabGroupService.activeTabs$ | async; as activeTabs) {
@if(tabgroups.length > 0) {
  <div class="flex flex-col w-full bg-surface soft-bottom-tab-bar">
    @for (tabgroup of tabgroups; track tabgroup.name; let last = $last) {
      <div class="flex flex-row w-full">
        @for (tab of tabgroup.tabs; track tab.name) {
          @if(tab.visible !== false) {
            <div class="flex border-t flex-col w-full justify-center items-center cursor-pointer hover:bg-hover"
                [ngClass]="{
                'text-lighter !cursor-not-allowed !bg-transparent': tab.disabled,
                'pb-4 pt-2': last,
                'bg-primary-50 border-primary border-t-4 text-primary': activeTabs[tabgroup.name] === tab.name,
                }"
                (click)="tab.disabled ? undefined : bottomTabGroupService.setActiveTab(tabgroup.name, tab.name)">
              <soft-icon class="m-2" *ngIf="tab.icon" [name]="tab.icon"></soft-icon>
              <span>{{tab.title | translate}}</span>
            </div>
          }
        }
      </div>
    }
  </div>
}
}
}
