import {ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider} from '@angular/core';
import { Store } from '@softline/core';
import { SoftAppsLogoutService } from './services/logout.service';
import { CommandStore, SOFTLINE_FEATURE_COMMANDS } from '@softline/application';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import { default as de } from '../i18n/de.json';
import { SOFTLINE_CONFIG_LOGIN } from "./login.shared";
import { LoginService, LogoutService } from '@softline/auth';
import { SoftAppsLoginService } from './services/login.service';

export const loginProviders: (Provider | EnvironmentProviders)[] = [
  {provide: SOFTLINE_CONFIG_LOGIN, useValue: {scan: false}},
  {provide: LogoutService, useExisting: SoftAppsLogoutService},
  {provide: LoginService, useExisting: SoftAppsLoginService},
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      const logoutService = inject(LogoutService);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'login',
          language: 'de',
          translations: de,
        }
      );
      store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
        name: 'login',
        commands: [
          {
            icon: 'fa-regular fa-arrow-right-from-bracket',
            name: '#LOGOUT.TITLE',
            class: 'menu main-menu main-menu-bottom info',
            execute: () => logoutService.logout(),
          },
        ],
      });
    },
    multi: true,
  },
];
