import { Injectable } from '@angular/core';
import { Modal } from './data/modal';

type Constructor<T> = new(...args: any[]) => T;

export const WithModal = <R = unknown, T extends Constructor<{}> = Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class ModalMixin extends Base implements Modal<R> {

    close!: (result: R) => void;

    constructor(...args: any[]) {
      super(...args);
    }

    registerCloseHandler(handler: (result: R) => void) {
      this.close = handler;
    }

  }
  return ModalMixin;
}
