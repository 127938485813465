import {
  AddOn,
  CommandStore,
  SOFTLINE_FEATURE_COMMANDS,
} from '@softline/application';
import {
  GeschaeftsfallStore,
  BusinessCaseComponent,
  SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
} from '@softapps/wws/gesamtabfrage';
import { base64Encode, Store } from '@softline/core';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SOFTLINE_PERMISSION_BUCHUNGSBRIEF } from '../buchungsbrief.shared';
import {
  AuthorizationStore,
  SOFTLINE_FEATURE_AUTHORIZATION,
} from '@softline/auth';
import { Router } from '@angular/router';

@Injectable()
export class GeschaeftsfallAddOn implements AddOn<BusinessCaseComponent> {
  type = BusinessCaseComponent;

  constructor(private store: Store, private router: Router) {}

  async onAttach(host: BusinessCaseComponent): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: GeschaeftsfallAddOn,
        priority: -1,
        commands: [
          {
            icon: 'fa-regular fa-envelope-open-text',
            name: '#BUCHUNGSBRIEF.ADD_ON.TITLE',
            class: 'menu action-menu action-menu-top',
            isVisible: this.store.observe(
              SOFTLINE_FEATURE_AUTHORIZATION,
              AuthorizationStore.getters.authorized,
              SOFTLINE_PERMISSION_BUCHUNGSBRIEF
            ),
            canExecute: this.store
              .observe(
                SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
                GeschaeftsfallStore.getters.selected
              )
              .pipe(
                map(
                  (o) =>
                    o.length === 1
                    && o[0].belegart.id === 'Rechnungspruefung'
                    && (o[0] as any).kzbelegart !== 'T'
                    && (o[0] as any).kzbelegart !== 'U'
                )
              ),
            execute: () => {
              const selected = this.store.get(
                SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
                GeschaeftsfallStore.getters.selected
              );
              if (selected.length !== 1) return;

              const beleg = selected[0];
              const fromRoute = {
                url: [
                  '/gesamtabfrage',
                  host.route.snapshot.paramMap.get('type'),
                  'business-case',
                  host.route.snapshot.paramMap.get('id'),
                ],
              };

              this.router.navigate(['/buchungsbrief', beleg.id], {
                queryParams: { from: base64Encode(JSON.stringify(fromRoute)) },
              });
            },
          },
        ],
      }
    );
  }

  async onDetach(host: any): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      GeschaeftsfallAddOn
    );
  }
}
