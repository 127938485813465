<div class="flex flex-col">
  <div class="flex items-center justify-between w-full">
    <div class="flex flex-row text-sm color-primary">
      <span>{{item.nummer}}</span>
      <span *ngIf="item.subnummer > 0">/{{item.subnummer}}</span>
    </div>
    @if (item[8] | isDefinedNotEmpty) {
      <div class="flex flex-row">
        <span class="text-sm text-light ">KST: {{item[8]}}</span>
      </div>
    }
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item[10]}} {{item[11]}} {{item[12]}}</span>
  </div>
  <div class="flex flex-row">
    <span>{{item[13]}} {{item[49]}}</span>
  </div>
  <div class="flex flex-row">
    <span>{{item[14]}} {{item[15]}}</span>
  </div>
</div>
