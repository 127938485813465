import {
  FieldOkConfig,
  FieldOkListViewComponent, FieldOkMasterDetailViewComponent, FieldOkMultiselectListViewComponent,
  FieldOkQueryViewComponent,
  FieldOkTableViewComponent, QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { KtostammKundeSubnummerListView } from "./list-view/ktostamm-kunde-subnummer.list-view";
import { KtostammKundeSubnummerQueryView } from "./query-view/ktostamm-kunde-subnummer.query-view";
import { KtostammKundeSubnummerMasterView } from "./master-detail-view/master-view/ktostamm-kunde-subnummer.master-view";
import { KtostammKundeSubnummerDetailView } from "./master-detail-view/detail-view/ktostamm-kunde-subnummer.detail-view";
import { KtostammKundeSubnummerTemplate } from "./template/ktostamm-kunde-subnummer.template";
import { FilterConfig, SortConfig } from "@softline/application";

const defaultSort= {property: 'nummer', direction: 'asc'}
const sort: SortConfig = {
  properties: [
    {property: 'nummer', title: 'Nummer'},
    {property: 'subnummer', title: 'Subnummer'},
    {property: '10', title: 'Nachname'},
    {property: '11', title: 'Vorname'},
    {property: '14', title: 'PLZ'},
    {property: '15', title: 'Ort'},
    {property: '13', title: 'Strasse'},
    {property: '49', title: 'Hausnummer'},
  ]
};
const filter: FilterConfig = {
  properties: [
    {property: 'nummer', title: 'Nummer'},
    {property: 'subnummer', title: 'Subnummer'},
    {property: '10', title: 'Nachname'},
    {property: '11', title: 'Vorname'},
    {property: '14', title: 'PLZ'},
    {property: '15', title: 'Ort'},
    {property: '13', title: 'Strasse'},
    {property: '49', title: 'Hausnummer'},
  ]
};


export const ktostammKundeSubnummerFieldOk: FieldOkConfig = {
  name: 'ktostamm.kunde.subnummer',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  autoQuery: true,
  template: KtostammKundeSubnummerTemplate,
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: KtostammKundeSubnummerQueryView
    },
  }, {
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: KtostammKundeSubnummerListView,
      sort,
      filter,
      defaultSort,
    }
  }, {
    name: 'masterDetail',
    component: FieldOkMasterDetailViewComponent,
    title: 'Detail',
    config: {
      masterComponent: KtostammKundeSubnummerMasterView,
      detailComponent: KtostammKundeSubnummerDetailView,
      sort,
      filter,
      defaultSort,
    }
  }]
};
export const ktostammKundeSubnummerMultiselectFieldOk: FieldOkConfig = {
  name: 'ktostamm.kunde.subnummer',
  type: 'multi',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: KtostammKundeSubnummerTemplate,
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: KtostammKundeSubnummerQueryView,
    },
  }, {
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl (mehrfach)',
    config: {
      component: KtostammKundeSubnummerListView,
      sort,
      filter,
      defaultSort,
    },
  }]
};
export const ktostammKundeSubnummerQueryFieldOk: FieldOkConfig = {
  name: 'ktostamm.kunde.subnummer',
  type: 'query',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: KtostammKundeSubnummerTemplate,
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: KtostammKundeSubnummerQueryView,
    },
  }, {
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl (mehrfach)',
    config: {
      component: KtostammKundeSubnummerListView,
      sort,
      filter,
      defaultSort,
    },
  }]
};

export function createKtostammKundeSubnummerFieldOkConfig(overrides?: Partial<FieldOkConfig>): FieldOkConfig {
  return {
    ...ktostammKundeSubnummerFieldOk,
    ...(overrides ?? {}),
  }
}
