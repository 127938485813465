<h4 class="text-xl font-semibold mt-3 ml-3">
  {{ title | translate | interpolate: params }}
</h4>

<p *ngIf="subtitle" class="mb-1 mt-1 ml-3 mr-3 border-box color-light">
  {{ subtitle | translate | interpolate: params }}
</p>

<p *ngIf="content" class="mb-1 mt-1 ml-3 mr-3 border-box">
  <ng-container *ngTemplateOutlet="content; context: (params ?? null)"></ng-container>
</p>
<form [formGroup]="form" (ngSubmit)="this.close($any(form.value.password))">
  <div class="flex flex-col mt-2">
    <label class="soft-label top required">Passwort</label>
    <soft-password-input formControlName="password" softFieldValidation></soft-password-input>
  </div>

  <div class="flex no-wrap mt-3">
    <button
      type="submit"
      class="soft-button accent ok h-14 w-full"
      [disabled]="form.invalid"
    >
      {{ '#UI_CORE.DIALOGS.OK' | translate }}
    </button>
  </div>
</form>

