import { Inject, Injectable } from '@angular/core';
import { Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_JWT_AUTHENTICATION,
  JwtAuthenticationStore, SOFTLINE_FEATURE_AUTHORIZATION, AuthorizationStore, LogoutService
} from '@softline/auth';
import {
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
} from '@softline/ui-core';
import { Router } from '@angular/router';
import { SOFTLINE_CONFIG_AUTHENTICATION_ROUTE } from '@softline/auth';

@Injectable({
  providedIn: 'root',
})
export class SoftAppsLogoutService extends LogoutService {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(SOFTLINE_CONFIG_AUTHENTICATION_ROUTE) private loginRoute: string
  ) {
    super();
  }

  async logout(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_JWT_AUTHENTICATION,
      JwtAuthenticationStore.actions.logout
    );
    await this.store.commit(
      SOFTLINE_FEATURE_AUTHORIZATION,
      AuthorizationStore.mutations.reset
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      '#LOGIN.MESSAGES.LOGOUT'
    );
    if(this.router.url.startsWith('/terminal'))
      return;
    await this.router.navigate([this.loginRoute]);
  }
}
