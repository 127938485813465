import {GesamtabfrageDownloadFile, GesamtabfrageDownloadStrategy} from "./gesamtabfrage-download-strategy";
import {inject, Injectable, LOCALE_ID} from "@angular/core";
import {Belegart} from "../../types/belegart.model";
import {ModalStore, SOFTLINE_FEATURE_MODAL} from "@softline/ui-core";
import {DateService, Store} from "@softline/core";
import {SOFTLINE_API_DOWNLOAD_RECEIPT} from "../../gesamtabfrage.api";
import {Beleg} from "../../types/beleg.model";
import {formatDate} from '@angular/common';

@Injectable()
export class FremdbelegDownloadStrategy implements GesamtabfrageDownloadStrategy {
  type = 'fremdbeleg';
  icon = 'fa-regular fa-receipt';
  label = '#GESAMTABFRAGE.ACTIONS.DOWNLOAD_FREMDBELEG';

  locale = inject(LOCALE_ID);

  constructor(private store: Store, private dateService: DateService) { }

  async getDownloadFiles(belege: Beleg[], belegart: Belegart): Promise<GesamtabfrageDownloadFile[]> {
    let combine = false;
    const files: GesamtabfrageDownloadFile[] = [];

    if(belege.length > 1) {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.ask,
        {
          title: '#GESAMTABFRAGE.MODAL.ASK.COMBINE.TITLE',
          question: '#GESAMTABFRAGE.MODAL.ASK.COMBINE.TEXT',
          showCancelAction: true,
          dismiss: true,
        });

      if (result === "DISMISSED" || result === 'CANCEL')
        return [];

      combine = result === 'YES';
    }

    if(combine)
      files.push({
        filename: `Fremdbelege_${formatDate(this.dateService.now(), 'dd-MM-YYYY_hh-mm', this.locale ?? 'de')}.pdf`,
        location: {path: SOFTLINE_API_DOWNLOAD_RECEIPT},
        body: { ids: belege.map(o => +o.id), zusammenfassen: true, belegart, fremdbelegdruck: false, fremdbelegdruckArchiv: true },
      })
    else
      for (const beleg of belege)
        files.push({
          filename: `${belegart.id}_${beleg.bezugsnummer ?? beleg.belegnummer}_fremd.pdf`,
          location: {path: SOFTLINE_API_DOWNLOAD_RECEIPT},
          body: { ids: [beleg.id], zusammenfassen: false, belegart, fremdbelegdruck: false, fremdbelegdruckArchiv: true },
        });
    return files;
  }
}
