<div class="w-full flex flex-col">
  @if (overline(); as overline) {
    <span class="soft-overline">{{overline}}</span>
  }
  @if (title(); as title) {
    <span class="font-bold">{{title}}</span>
  }
  @if (subtitle(); as subtitle) {
    <span class="text-light text-xs mt-1">{{subtitle}}</span>
  }
</div>
<ng-content></ng-content>
