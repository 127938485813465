<form [formGroup]="loginForm" (ngSubmit)="submit(loginForm.value)">
  <div class="px-2 md:px-0">
    <h4 class="text-xl font-semibold mt-3">
      Login
    </h4>
    <div class="flex flex-col w-full mt-2">
      <label class="soft-label top required">Benutzername</label>
      <soft-input
        type="email"
        formControlName="username"
        id="softapps-allgemein-login-username"
        placeholder="{{ '#LOGIN.USERNAME' | translate }}"
        autocomplete="username"
      ></soft-input>
    </div>
    <div class="flex flex-col w-full mt-2">
      <label class="soft-label top required">Passwort</label>
      <soft-password-input
        formControlName="password"
        id="softapps-allgemein-login-password"
        placeholder="{{ '#LOGIN.PASSWORD' | translate }}"
        autocomplete="current-password"
      >
      </soft-password-input>
    </div>
    <div class="c mt-3">
      <soft-checkbox id="softapps-allgemein-login-remember"
                     formControlName="remember">
        <span class="soft-label right">{{ '#LOGIN.REMEMBER' | translate }}</span>
      </soft-checkbox>
    </div>
  </div>
  <div class="mt-3">
    <button
      id="softapps-allgemein-login-submit"
      class="soft-button soft-button-primary w-full !py-4"
      [disabled]="loading()"
      type="submit"
    >
      <i *ngIf="loading()" class="fa-regular fa-spinner fa-spin"></i>
      {{ '#LOGIN.LOGIN' | translate }}
    </button>
  </div>
</form>
