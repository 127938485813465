import { Routes } from '@angular/router';
import { AuthenticationGuard } from '@softline/auth';
import { loginRoutes } from '@softapps/allgemein/login';
import {
  connectionSettingsRoutes,
  settingsRoutes,
} from '@softapps/allgemein/utils';
import { passwordRoutes } from '@softapps/allgemein/password';
import { moduleRoutes } from '@softapps/allgemein/modules';
import { pdfEditorRoutes } from '@softapps/allgemein/pdf-editor';
import { gesamtabfrageRoutes } from '@softapps/wws/gesamtabfrage';
import { subkundenRoutes } from '@softapps/wws/subkunden';
import { freigabeRoutes } from '@softapps/fibu/freigabe';
import { haftruecklassRoutes } from '@softapps/fibu/haftruecklass';
import { buchungsbriefRoutes } from '@softapps/abau/buchungsbrief';
import { ssoRoutes } from '@softapps/allgemein/sso';
import { offenePostenRoutes } from "@softapps/fibu/offene-posten";
import { ENVIRONMENT_INITIALIZER } from '@angular/core';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@softapps/core').then((o) => o.ShellComponent),
    children: [
      ...moduleRoutes,
      ...pdfEditorRoutes,
      ...settingsRoutes,
      ...gesamtabfrageRoutes,
      ...subkundenRoutes,
      ...freigabeRoutes,
      ...haftruecklassRoutes,
      ...offenePostenRoutes,
      ...buchungsbriefRoutes,
    ],
    canActivate: [AuthenticationGuard],
  },
  {
    path: '',
    loadComponent: () =>
      import('@softapps/core').then((o) => o.PublicShellComponent),
    children: [
      ...loginRoutes,
      ...ssoRoutes,
      ...passwordRoutes,
      ...connectionSettingsRoutes,
      ...settingsRoutes,
    ],
  },
];
