import { Component, TemplateRef } from "@angular/core";
import { Modal } from "../../data/modal";
import { UiCorePipesModule } from "../../../pipes/ui-core-pipes.module";
import { I18nModule } from "../../../i18n/i18n.module";
import { UiCoreComponentsModule } from "../../../components/ui-core-components.module";
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FieldValidationDirective } from "../../../directives/field-validation.directive";
import { Validators } from "../../../validation/validators.class";

@Component({
  selector: 'soft-template-modal',
  standalone: true,
  imports: [CommonModule, UiCorePipesModule, I18nModule, UiCoreComponentsModule, ReactiveFormsModule, FieldValidationDirective],
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss'],
})
export class PasswordModalComponent implements Modal<string> {
  close!: (result: string) => void;

  form = new FormGroup({
    password: new FormControl<string>('', [Validators.required()]),
  })

  title: string | undefined;
  subtitle?: string;
  content?: TemplateRef<any>;
  params?: object;

  registerCloseHandler(handler: (result: string) => void): void {
    this.close = handler;
  }
}
