import { input, OutputEmitterRef, Signal } from '@angular/core';

export type StepState = 'default' | 'disabled' | 'error' | 'hidden' | 'manual';

export abstract class WizardItemBase {
  abstract title: Signal<string>;
  abstract description: Signal<string | null | undefined>;
  abstract icon: Signal<string | undefined>;

  abstract state: Signal<StepState>;
  abstract canEnter: Signal<boolean>;
  abstract canLeave: Signal<boolean>;

  abstract enter: OutputEmitterRef<this>;
  abstract leave: OutputEmitterRef<this>;
  abstract next: OutputEmitterRef<{
    current: WizardItemBase;
    next: WizardItemBase;
  }>;
}
