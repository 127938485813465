<div class="flex flex-row flex-nowrap w-full">
  <soft-input
    placeholder="{{
        '#APPLICATION.FILTER_AND_SORT.PLACEHOLDER' | translate
      }}"
    [value]="filterText$ | async"
    (valueChange)="filterText$.next($event ?? '')">
    <div class="cursor-pointer select-none"
         [class.text-lighter]="!(caseSensitive$ | async)"
         (click)="caseSensitive$.next(!caseSensitive$.value)">Cc</div>
    <div class="cursor-pointer select-none ml-2"
         [class.text-lighter]="!(wholeText$ | async)"
         (click)="wholeText$.next(!wholeText$.value)">W</div>
  </soft-input>
  <div *ngIf="filterConfig || sortConfig"
       class="relative h-8 w-8 ml-2">
    <button class="flex flex-row justify-center items-center rounded bg-secondary-200 hover:bg-secondary-400 disabled:bg-secondary-200 h-full w-full"
            [disabled]="(values?.length ?? 0) === 0"
            [ngClass]="{'!bg-primary !hover:bg-primary-700 !text-primary-contrast': ((children$ |async)?.length ?? 0) > 0}"
            (click)="onOpenDialog()">
      <soft-icon name="fa-regular fa-bars-filter"></soft-icon>
    </button>
    <div *ngIf="((children$ | async)?.length ?? 0) > 0" class="soft-badge !fixed !top-auto !left-auto !right-auto !bottom-auto -mt-[2.275rem] ml-[1.2rem]">!</div>
  </div>
</div>
